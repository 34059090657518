.card-img-expand {
  clip-path: polygon(50% 0, 100% 50%, 50% 100%, 0 50%);
}

.card:hover .card-img {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

[data-link] {
  cursor: pointer;
}
